<template lang="pug">
  v-container(fluid)
    v-expand-transition
      v-alert(
        v-if="asset && nAError > 0"
        type="error"
        border="left"
        elevation="2"
        icon="mdi-bell-alert-outline"
        dense
        prominent
        transition="slide-y-transition"
      )
        span {{ $tc('assets.alarm.error.active', nAError, { asset: asset.name, n: nAError }) }}
      v-alert(
        v-if="asset && nAWarning > 0"
        type="warning"
        border="left"
        elevation="2"
        icon="mdi-bell-outline"
        dense
        prominent
        transition="slide-y-transition"
      )
        span {{ $tc('assets.alarm.warning.active', nAWarning, {asset: asset.name, n: nAWarning }) }}
    v-expand-transition
      v-alert(
        v-if="asset && asset.malfunction"
        type="warning"
        border="left"
        elevation="2"
        icon="mdi-alert-circle-outline"
        dense
        prominent
        transition="slide-y-transition"
      )
        v-row(align="center")
          v-col.grow
            strong {{ $t('assets.malfunction.reported-item', { item: asset.name }) }}
            br
            span {{ asset.malfunction.text }}
            br
            v-icon(left small) mdi-update
            small
              em {{ asset.malfunction.dateTime | toMoment | momentFromNow }}
          v-col.shrink
            v-btn(
              color="success"
              @click="clearMalfunction()"
              :loading="updatingAsset"
              :disabled="updatingAsset"
            )
              v-icon(left) mdi-alert-circle-check-outline
              | {{ $t('assets.malfunction.clear') }}
    v-row
      v-col(cols="12" sm="4" md="3")
        v-card(:loading="updatingAsset")
          v-skeleton-loader(
            v-if="loadingAsset"
            type="list-item-avatar-two-line, list-item, list-item, card, list-item-avatar-two-line"
          )
          template(v-else)
            v-list-item(v-if="getAssetType(asset.assetTypeId)")
              v-list-item-avatar(color="primary")
                v-icon(color="white") {{ getAssetType(asset.assetTypeId).icon }}
              v-list-item-content
                v-list-item-title {{ asset.name }}
                v-list-item-subtitle {{ getAssetType(asset.assetTypeId).name }}
              v-list-item-action
                v-menu(
                  bottom
                  left
                )
                  template(v-slot:activator="{ on, attrs }")
                    v-btn(
                      icon
                      v-bind="attrs"
                      v-on="on"
                    )
                      v-icon mdi-dots-vertical
                  v-list(dense)
                    v-list-item(
                      @click="copyText(asset.id)"
                    )
                      v-list-item-icon
                        v-icon mdi-identifier
                      v-list-item-content
                        v-list-item-title {{ $t('item.copy', { item: "Asset ID" }) }}
            v-list(dense)
              v-list-item
                v-list-item-avatar
                  OrganizationAvatar(
                    :organization-id="asset.organizationId"
                    size="32"
                  )
                v-list-item-content
                  v-list-item-title {{ organizationName }}
              v-list-item
                v-list-item-icon
                  v-icon mdi-update
                v-list-item-content
                  v-list-item-title
                    template(v-if="asset.lastContact")
                      | {{ asset.lastContact | momentFromNow }}
                    template(v-else) {{ $t('never') }}
              v-list-item
                v-list-item-icon
                  v-icon mdi-ip
                v-list-item-content
                  v-list-item-title
                    | {{ asset.ip && asset.ip.length ? asset.ip : $t('vpn.no-ip-and-vpn') }}
              v-list-item
                v-list-item-icon
                  v-icon(v-if="asset.sensors.length") mdi-lan-connect
                  v-icon(v-if="!asset.sensors.length") mdi-lan-disconnect
                v-list-item-content
                  v-list-item-title(v-if="asset.connectors.length") Live data
                  v-list-item-title(v-else-if="asset.meta && asset.meta.source")
                    | Via {{ asset.meta.source }}
                  v-list-item-title(v-else) {{ $t('no-direct-data-connection') }}
                v-list-item-action(v-if="asset.inAzure")
                  v-menu(
                    v-if="asset.inAzure"
                    :close-on-content-click="false"
                  )
                    template(v-slot:activator="{ on: menu, attrs }")
                      v-tooltip(top)
                        template(v-slot:activator="{ on: tooltip }")
                          v-btn(
                            :loading="isLoadingAssetConfig"
                            :disabled="isLoadingAssetConfig"
                            v-bind="attrs"
                            v-on="{ ...tooltip, ...menu }"
                            small
                            icon
                          )
                            v-icon mdi-cloud-download
                        span RUT955 {{ $t('configuration') }}
                    v-form(
                      v-model="validDownloadConfig"
                      ref="formDownloadConfig"

                    )
                      v-card(:loading="isLoadingAssetConfig" :disabled="isLoadingAssetConfig")
                        v-card-title RUT955 {{ $t('configuration-generate') }}
                        v-card-text
                          v-text-field(
                            v-model="downloadConfig.adminPassword"
                            :label="`Admin ${$t('password')}`"
                            :rules="rules"
                            required
                            :append-icon="showAdminPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showAdminPassword ? 'text' : 'password'"
                            @click:append="showAdminPassword = !showAdminPassword"
                            counter
                          )
                          v-text-field(
                            v-model="downloadConfig.wifiPassword"
                            :label="`WiFi ${$t('password')}`"
                            :rules="rules"
                            required
                            :append-icon="showWifiPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showWifiPassword  ? 'text' : 'password'"
                            @click:append="showWifiPassword  = !showWifiPassword "
                            counter
                          )
                        v-card-actions
                          v-spacer
                          v-btn(
                            :disabled="!validDownloadConfig || loadingAsset"
                            :loading="loadingAsset"
                            text
                            color="primary"
                            @click="download()"
                          )
                            v-icon(left) mdi-cloud-download
                            | {{ $t('configuration-download') }}
              AssetsMap(
                v-if="asset.getLatLng()"
                height="200"
                :assets="[asset]"
                :center="asset.getLatLng()"
                :zoom="15"
              )
            template(v-if="asset.ip")
              v-subheader VPN
              v-list-item
                v-list-item-avatar(color="grey lighten-4")
                  v-icon(v-if="isRightVPN" color="green") mdi-shield-lock
                  v-icon(v-else-if="hasVPN" color="warning") mdi-shield-lock
                  v-icon(v-else color="red") mdi-shield-off
                v-list-item-content
                  v-list-item-title
                    span(v-if="isRightVPN") {{ $t('vpn.connected') }}
                    span(v-else-if="hasVPN") {{ $t('vpn.invalid') }}
                    span(v-else) {{ $t('vpn.disconnected') }}
                  v-list-item-subtitle {{ $t('vpn.connection') }}
                v-list-item-action(v-if="!isRightVPN")
                  v-btn(icon small @click="showExtraVPNInfo = !showExtraVPNInfo")
                    v-icon
                      template(v-if="showExtraVPNInfo") mdi-chevron-up
                      template(v-else) mdi-chevron-down
              v-expand-transition
                v-card-text(v-if="!isRightVPN && showExtraVPNInfo")
                  p(v-if="asset.vpnIp") {{ $t('vpn.connect-ip', { ip: asset.vpnIp }) }}
                  p(v-else) {{ $t('vpn.fill-ip') }}
                  p(v-html="$t('vpn.contact', { organization: currentOrganizationName })")
          v-divider
          v-card-actions
            v-btn(small text color="primary" :to="{ name: 'assets' }")
              v-icon(left) mdi-chevron-left
              | {{ $t('assets.all') }}
            v-spacer
            v-menu(
              v-if="asset"
              v-model="malfunctionMenu"
              bottom
              left
              :close-on-content-click="false"
            )
              template(v-slot:activator="{ on: menu, attrs }")
                v-tooltip(top)
                  template(v-slot:activator="{ on: tooltip }")
                    v-btn(
                      :disabled="!!asset.malfunction"
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }"
                      small
                      icon
                    )
                      v-icon
                        template(v-if="loadingAsset") mdi-spin mdi-loading
                        template(v-else) mdi-alert-circle-outline
                  span {{ $t('assets.malfunction.report') }}
              v-card(
                width="300"
              )
                v-form(
                  v-model="validMalfunction"
                  ref="formMalfunction"
                )
                  v-card-title {{ $t('assets.malfunction.report') }}
                  v-card-text
                    v-text-field(
                      v-model="malfunctionText"
                      :label="`${$t('assets.malfunction.description')}`"
                      :rules="[mandatoryRule]"
                      required
                    )
                  v-divider
                  v-card-actions()
                    v-spacer
                    v-btn(
                      @click="reportMalfunction()"
                      :disabled="loadingMalfunction || !!asset.malfunction"
                      :loading="loadingMalfunction"
                      text
                      color="warning"
                    )
                      v-icon(left) mdi-send-outline
                      | {{ $t('send') }}
        //- v-skeleton-loader(v-else elevation="2" type="list-item-avatar-two-line")
      v-col(cols="12" sm="8" md="9")
        v-row
          v-col(
            cols="12"
            md="4"
            v-for="(c, i) in allComponents"
            :key="i"
          )
            v-card(
              color="primary"
              dark
              :href="$options.filters.determineScheme(c.ip)"
              target="_blank"
              :disabled="!isRightVPN"
            )
              v-list-item
                v-list-item-avatar
                  v-icon {{ c.isRdp ? 'mdi-remote-desktop' : 'mdi-desktop-mac-dashboard' }}
                v-list-item-content
                  v-list-item-title {{ c.name }}
                  v-list-item-subtitle {{ c.ip | determineScheme }}
              v-card-actions
                v-spacer
                v-btn(
                  text
                )
                  | {{ $t('open') }}
                  v-icon(right) mdi-open-in-new
        v-row(v-if="loadingAsset")
          v-col(cols="12" md="4" v-for="i in Array(6)" :key="i")
            v-card
              v-skeleton-loader(type="list-item-avatar-three-line")
          v-col(cols="12")
            v-card
              v-skeleton-loader(type="image")
        v-row(v-else)
          v-col(v-for="(sensor, index) in kpiSensors" :key="`${sensor.id}-kpi`")
            v-card
              v-list-item(three-line)
                v-list-item-avatar(color="primary")
                  v-avatar(size="40")
                    span.white--text.headline {{ index + 1 }}
                v-list-item-content
                  v-list-item-title
                    template(v-if="sensor.isBoolean")
                      v-icon(
                        v-if="sensor.lastMeasurement.value"
                        color="success"
                      ) mdi-check-circle
                      v-icon(v-else color="primary") mdi-close-circle
                    template(v-else)
                      | {{ sensor.lastMeasurement.value | round }}
                      span.grey--text(v-html="sensor.sensorType.unit")
                  v-list-item-subtitle
                    | {{ sensor.sensorType.name }}
                  v-list-item-subtitle
                    v-icon(left small) mdi-update
                    | {{ sensor.lastMeasurement.dateTime | toMoment | momentFromNow }}
        v-row(v-if="graphKpis.length > 0")
          v-col(
            v-for="(kpi, index) in graphKpis"
            :key="`${index}-kpi`"
            cols="12"
            :md="(12 / graphKpis.length)"
          )
            v-card
              v-tabs(
                v-model="kpiTabs[index]"
                grow
                dense
              )
                v-tab(
                  @click="updateGraph('realtime', index)"
                ) {{ $t('real-time') }}
                  v-icon(
                    style="position:absolute; left: 0.25em;"
                    small
                    color="primary"
                  ) mdi-loading mdi-spin
                v-tab(
                  @click="updateGraph('history', index)"
                  :disabled="!kpi.history.series.length"
                ) {{ $t('historic') }}
              v-tabs-items(
                v-model="kpiTabs[index]"
              )
                v-tab-item
                  apexchart(
                    v-if="showRealtimeGraph(kpi)"
                    :options="kpi.realtime.options"
                    :series="kpi.realtime.series"
                    height="200"
                    group="asset"
                    :id="`graph-realtime-${index}`"
                    :ref="`graph-realtime-${index}`"
                  )
                  v-card(v-else flat)
                    v-skeleton-loader(type="image")
                v-tab-item
                  v-card-text(
                    v-show="false"
                  )
                    v-chip-group(
                      v-model="kpiGraphSetting[index]"
                      mandatory
                    )
                      v-chip(
                        x-small
                        outlined
                        v-for="d in graphDates"
                        :key="d.key"
                        :value="d.key"
                      ) {{ d.value }}
                  v-card(v-if="isLoadingSensorMeasurementsSensors(index)" flat)
                    v-skeleton-loader(type="image")
                  apexchart(
                    v-else
                    :options="kpi.history.options"
                    :series="kpi.history.series"
                    height="200"
                    group="asset"
                    :id="`graph-history-${index}`"
                    :ref="`graph-history-${index}`"
                  )
        v-row(v-if="loadingAsset || properties.length > 0")
          v-col(cols="12" md="7")
            v-subheader {{ $t('properties') }}
            v-card
              v-skeleton-loader(v-if="loadingAsset" type="table")
              template(v-else)
                v-card-text
                  v-text-field(
                    v-model="search"
                    :label="$t('properties-search')"
                    prepend-icon="mdi-magnify"
                    clearable
                    hide-details
                    dense
                    single-line
                  )
                v-data-table(
                  :items="properties"
                  dense
                  :headers="propHeaders"
                  item-key="sensorId"
                  hide-default-footer
                  :page.sync="propPage"
                  @page-count="propPageCount = $event"
                  :search="search"
                  single-expand
                  @click:row="(e, { expand, isExpanded }) => expand(!isExpanded)"
                )
                  template(v-slot:expanded-item="{ headers, item }")
                    td(:colspan="propHeaders.length")
                      v-chip(x-small)
                        v-icon(left small) mdi-upload-outline
                        | {{ item.source }}
                  template(v-slot:item.name="{ item }")
                    span(:title="item.sensorTypeId") {{ item.name }}
                  template(v-slot:item.value="{ item }")
                    template(v-if="item.hasAlarmWarning")
                      v-icon.mr-2(small color="warning") mdi-bell-outline
                    template(v-if="item.hasAlarmError")
                      v-icon.mr-2(small color="error") mdi-bell-alert-outline
                    v-icon(v-if="item.isAlarm" left small color="error") mdi-bell-ring-outline
                    template(v-else-if="item.isBoolean")
                      v-icon(v-if="item.value" small color="success") mdi-check-circle
                      v-icon(v-else small color="primary") mdi-close-circle
                    template(v-else)
                      | {{ item.value | round }}&nbsp;
                      span.grey--text(v-html="item.unit")
                  template(v-slot:item.dateTime="{ item }")
                    | {{ item.dateTime | toMoment | momentFromNow }}
                  template(v-slot:item.actions="{ item }")
                    v-menu(
                      bottom
                      left
                    )
                      template(v-slot:activator="{ on, attrs }")
                        v-btn(
                          icon
                          small
                          v-bind="attrs"
                          v-on="on"
                        )
                          v-icon(small) mdi-dots-vertical
                      v-list(dense)
                        v-list-item(
                          @click="copyText(item.sensorId)"
                        )
                          v-list-item-icon
                            v-icon mdi-identifier
                          v-list-item-content
                            v-list-item-title {{ $t('item.copy', { item: "Sensor ID" }) }}
                        v-list-item(
                          @click="copyText(item.sensorTypeId)"
                        )
                          v-list-item-icon
                            v-icon mdi-identifier
                          v-list-item-content
                            v-list-item-title {{ $t('item.copy', { item: "Sensor Type ID" }) }}
                v-divider
                v-pagination.mt-2(
                  v-if="propPageCount > 1"
                  v-model="propPage"
                  :length="propPageCount"
                  total-visible="7"
                )
          v-col(cols="12" md="5")
            v-subheader {{ $tc('alarms.alarm', 2) }}
            v-card
              v-skeleton-loader(
                v-if="loadingAsset"
                type="list-item-avatar-two-line, list-item-avatar-two-line"
              )
              v-list(v-else-if="asset && alarms && alarms.length > 0")
                template(
                  v-for="(alarm, i) in alarms"
                )
                  v-list-item(
                    dense
                    :key="`${i}`"
                  )
                    v-list-item-avatar
                      v-icon(:color="alarmColor(alarm)") {{ alarmIcon(alarm) }}
                    v-list-item-content
                      v-list-item-title {{ alarm.sensor.sensorType.name }}
                      v-list-item-subtitle(v-if="alarm.alarm") {{ alarm.alarm.name }}
                      v-list-item-subtitle
                        v-icon(left small) mdi-clock-outline
                        | {{ alarm.since.dateTime | toMoment | momentFromNow }}
                        template(v-if="alarm.delayPassed")
                          v-icon(right left small color="error") mdi-bell-alert-outline
                          | {{ alarm.delayPassed.dateTime | toMoment | momentFromNow }}
                        template(v-if="alarm.cleared")
                          v-icon(right left small color="success") mdi-bell-check-outline
                          | {{ alarm.cleared.dateTime | toMoment | momentFromNow }}
                    v-list-item-action
                      v-menu(
                        bottom
                        left
                      )
                        template(v-slot:activator="{ on, attrs }")
                          v-btn(
                            small
                            icon
                            v-bind="attrs"
                            v-on="on"
                          )
                            v-icon mdi-dots-vertical
                        v-list(dense)
                          v-list-item(
                            @click="copyText(alarm.id)"
                          )
                            v-list-item-icon
                              v-icon mdi-identifier
                            v-list-item-content
                              v-list-item-title {{ $t('item.copy', { item: "Alarm ID" }) }}
                  v-divider(
                    v-if="i != (alarms.length - 1)"
                    inset
                    :key="`${i}-divider`"
                  )
              v-list-item(
                v-else
                dense
              )
                v-list-item-avatar
                  v-icon(color="success") mdi-bell-check-outline
                v-list-item-content
                  v-list-item-title {{ $tc('alarms.alarm', 0) }}
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import {
  LMarker,
  LIcon,
} from 'vue2-leaflet';
import colors from 'vuetify/lib/util/colors';
import _ from 'underscore';

import {
  SENSOR_TYPE_CONTINUOUS,
  SENSOR_TYPE_ALARM,
  SENSOR_TYPE_BOOLEAN,
} from '@/helpers/consts';
import AssetsMap from '@/components/assets/AssetsMap.vue';
import OrganizationAvatar from '@/components/organization/OrganizationAvatar.vue';
import { AlarmTypes } from '@/models/alarm';

export default Vue.extend({
  name: 'Asset',

  props: {
    assetId: {
      type: String,
      default: null,
    },
  },
  components: {
    LMarker,
    LIcon,
    AssetsMap,
    OrganizationAvatar,
  },
  data() {
    return {
      showExtraVPNInfo: false,
      kpiTabs: {},
      kpiGraphSetting: {},
      graphDates: [
        { key: 'month', value: this.$t('date.month') },
        { key: 'week', value: this.$t('date.week') },
        { key: 'day', value: this.$t('date.day') },
        { key: 'hour', value: this.$t('date.hour') },
      ],
      propHeaders: [
        {
          text: this.$t('description'),
          sortable: true,
          groupable: false,
          value: 'name',
        },
        {
          text: this.$t('value'),
          sortable: false,
          groupable: false,
          value: 'value',
          align: 'end',
        },
        {
          text: this.$t('value-last'),
          sortable: true,
          groupable: false,
          value: 'dateTime',
        },
        {
          text: this.$t('actions'),
          sortable: true,
          groupable: false,
          value: 'actions',
          align: 'end',
        },
      ],
      propPage: 1,
      propPageCount: 0,
      search: null,
      checkVPNInterval: null,
      validDownloadConfig: false,
      downloadConfig: {
        adminPassword: '',
        wifiPassword: '',
      },
      rules: [
        (v) => !!v || this.$t('validation.mandatory'),
        (v) => v.length >= 8 || this.$tc('validation.chars-min', 8, { n: 8 }),
      ],
      showAdminPassword: false,
      showWifiPassword: false,
      loadingMalfunction: false,
      malfunctionText: '',
      validMalfunction: false,
      malfunctionMenu: false,
    };
  },
  mounted() {
    this.$store.dispatch('GET_ALARMS');
    this.$store.dispatch('GET_SENSOR_TYPES');
  },
  computed: {
    ...mapGetters([
      'getAsset',
      'isLoadingAssets',
      'isLoadingAssetConfig',
      'hasVPN',
      'getVPNIp',
      'getAssetType',
      'getOrganization',
      'currentOrganization',
      'getRealtimeMeasurements',
      'getSensorType',
      'getAlarmsForAssetId',
      'getAlarmsForSensor',
      'isLoadingSensorMeasurements',
      'getSensorMeasurements',
      'getAlarm',
    ]),
    nAError() {
      return this.asset.numberOfAlarmErrors;
    },
    nAWarning() {
      return this.asset.numberOfAlarmWarnings;
    },
    organizationName() {
      return this.getOrganization(this.asset?.organizationId)?.name;
    },
    currentOrganizationName() {
      return this.currentOrganization?.name;
    },
    loadingAsset() {
      return this.isLoadingAssets && !this.asset;
    },
    updatingAsset() {
      return this.isLoadingAssets || this.loadingMalfunction;
    },
    asset() {
      return this.getAsset(this.assetId);
    },
    properties() {
      if (!this.asset) return [];

      const props = this.asset.sensors.map((s) => {
        const sensor = {
          name: s.sensorType.name,
          unit: s.sensorType.unit,
          value: s.lastMeasurement.value,
          dateTime: s.lastMeasurement.dateTime,
          sensorTypeId: s.sensorType.id,
          sensorId: s.id,
          source: s.meta.source,
          isAlarm: s.sensorType.dataType === SENSOR_TYPE_ALARM,
          isContinuous: s.sensorType.dataType === SENSOR_TYPE_CONTINUOUS,
          isBoolean: s.sensorType.dataType === SENSOR_TYPE_BOOLEAN
            || _.isBoolean(s.lastMeasurement.value),
          hasAlarmError: this.asset.sensorHasAlarmError(s.id),
          hasAlarmWarning: this.asset.sensorHasAlarmWarning(s.id),
        };
        return sensor;
      });
      return props;
    },
    alarms() {
      const alarms = this.asset.alarms.map((alarmSensor) => ({
        ...alarmSensor,
        alarm: this.getAlarm(alarmSensor.alarmId),
        sensor: this.asset.getSensor(alarmSensor.sensorId),
      }));
      return alarms;
    },
    kpiSensors() {
      const kpis = this.getAssetType(this.asset?.assetTypeId)?.kpis || [];

      const sensors = kpis
        .map((sensorTypeId) => this.asset.getSensorBySensorTypeId(sensorTypeId))
        .filter((s) => s)
        .map((s) => ({
          ...s,
          isAlarm: s.sensorType.dataType === SENSOR_TYPE_ALARM,
          isContinuous: s.sensorType.dataType === SENSOR_TYPE_CONTINUOUS,
          isBoolean: _.isBoolean(s.lastMeasurement.value),
        }));
      return sensors;
    },
    graphKpis() {
      const sensors = this.kpiSensors;

      const continuousSensors = sensors
        .filter((sensor) => sensor.sensorType.dataType === SENSOR_TYPE_CONTINUOUS);

      const graphs = [];
      if (continuousSensors.length > 0) {
        graphs.push({
          sensors: continuousSensors,
          realtime: {
            series: this.getSeriesRealTime(continuousSensors),
            options: this.getChartOptionsRealTime(continuousSensors),
          },
          history: {
            series: this.getSeriesHistory(continuousSensors, this.kpiGraphSetting[0]),
            options: this.getChartOptionsHistory(continuousSensors, this.kpiGraphSetting[0]),
          },
        });
      }
      return graphs;
    },
    isRightVPN() {
      return this.hasVPN && this.getVPNIp === this.asset?.vpnIp;
    },
    allComponents() {
      if (!this.asset) return [];
      const components = this.asset?.components || [];
      const gateway = (this.asset.ip) ? [{
        name: this.asset?.name,
        ip: this.$options.filters.determineScheme(this.asset?.ip),
      }] : [];

      return gateway.concat(components).map((c) => {
        const scheme = c.ip.split('//')?.[0];
        return {
          ...c,
          isRdp: scheme === 'rdp:',
        };
      });
    },
  },
  methods: {
    alarmColor(alarmSensor) {
      if (alarmSensor.cleared) return 'success';
      if (alarmSensor.delayPassed) return 'error';
      return 'warning';
    },
    alarmIcon(alarmSensor) {
      if (alarmSensor.cleared) return 'mdi-bell-check-outline';
      if (alarmSensor.delayPassed) return 'mdi-bell-alert-outline';
      return 'mdi-bell-outline';
    },
    getSeriesRealTime(sensors) {
      if (!this.asset) return [];
      return sensors.map((sensor) => {
        const { sensorType } = sensor;
        return {
          name: sensorType?.name,
          data: this.getRealtimeMeasurements(sensor.id)
            ?.map((i) => ({ x: i.dateTime, y: i.value })) || [],
        };
      });
    },
    getSeriesHistory(sensors, last) {
      if (!this.asset) return [];

      const series = sensors
        .filter((sensor) => sensor.sensorType.history)
        .map((sensor) => {
          const { sensorType } = sensor;
          return {
            name: sensorType?.name,
            data: this.getSensorMeasurements({
              sensorId: sensor.id,
              assetId: this.asset.id,
              last,
            })
            ?.map((i) => ({ x: i.dateTime, y: i.value })) || [],
          };
        });
      return series;
    },
    getChartOptionsRealTime(sensors) {
      const annotationsYaxis = [];

      sensors.forEach((sensor) => {
        const alarms = this.getAlarmsForSensor(sensor);
        // let lineColors = false;
        alarms.forEach((alarm) => {
          if (alarm.alarmType === AlarmTypes.LowHigh) {
            annotationsYaxis.push({
              y: alarm.alarmLow,
              y2: alarm.alarmHigh,
              strokeDashArray: 3,
              borderColor: colors.grey.darken3,
              fillColor: colors.grey.lighten1,
              opacity: 0.2,
              offsetX: 0,
              offsetY: -3,
              width: '100%',
              yAxisIndex: 0,
              label: {
                borderColor: colors.grey.base,
                borderWidth: 1,
                borderRadius: 2,
                text: `${this.$tc('alarms.alarm', 1)} ${alarm.name}: ${alarm.alarmLow} - ${alarm.alarmHigh}${sensor.sensorType.unit}`,
                textAnchor: 'end',
                position: 'right',
                offsetX: 0,
                offsetY: 0,
              },
            });
            // lineColors = [({ value }) => {
            //   if (value < alarm.alarmLow || value > alarm.alarmHigh) {
            //     return vuetify.framework.theme.themes.light.error;
            //   }
            //   return vuetify.framework.theme.themes.light.primary;
            // }];
          }
        });
      });
      const chartOptions = JSON.parse(JSON.stringify(this.chartOptions));
      chartOptions.markers = {
        size: 3,
      };
      chartOptions.annotations = {
        position: 'front',
        yaxis: annotationsYaxis,
      };
      if (sensors.length) {
        chartOptions.yaxis.title = {
          text: sensors[0].sensorType.unit,
        };
      }
      return chartOptions;
    },
    getChartOptionsHistory(sensors) {
      const chartOptions = JSON.parse(JSON.stringify(this.getChartOptionsRealTime(sensors)));

      chartOptions.markers = {
        size: 0,
      };
      chartOptions.stroke.curve = 'smooth';
      chartOptions.stroke.width = 2;

      return chartOptions;
    },
    showRealtimeGraph(kpi) {
      return kpi
        && kpi.realtime.series
        && kpi.realtime.series.length
        && kpi.realtime.series[0].data.length;
    },
    isLoadingSensorMeasurementsSensors(index) {
      const { sensors } = this.graphKpis[index];
      return !!sensors.filter((sensor) => this.isLoadingSensorMeasurements({
        sensorId: sensor.id,
        assetId: this.asset.id,
        last: this.kpiGraphSetting[index],
      })).length;
    },
    reportMalfunction() {
      this.$refs.formMalfunction.validate();
      this.$nextTick(() => {
        if (!this.validMalfunction) return;
        this.loadingMalfunction = true;
        const malfunction = {
          text: this.malfunctionText,
          dateTime: this.$moment.utc().toISOString(),
        };
        this.$store.dispatch('ASSET_REPORT_MALFUNCTION', { asset: this.asset, malfunction })
          .then(() => {
            this.malfunctionMenu = false;
            this.malfunctionText = '';
          })
          .finally(() => {
            this.loadingMalfunction = false;
          });
      });
    },
    clearMalfunction() {
      this.loadingMalfunction = true;
      this.$store.dispatch('ASSET_CLEAR_MALFUNCTION', { asset: this.asset })
        .finally(() => {
          this.loadingMalfunction = false;
        });
    },
    download() {
      this.$refs.formDownloadConfig.validate();
      this.$nextTick(() => {
        if (!this.validDownloadConfig) return;
        this.$store
          .dispatch('DOWNLOAD_ASSET_CONFIG_RUT955', {
            asset: this.asset,
            ...this.downloadConfig,
          });
      });
    },
    updateGraph(type, index) {
      this.$nextTick(() => {
        const graph = this.$refs[`graph-${type}-${index}`];
        setTimeout(() => {
          if (graph) {
            if (Array.isArray(graph) && graph.length > 0) {
              graph[0].refresh();
            } else {
              graph.refresh();
            }
          }
        }, 100);
      });
    },
  },
  watch: {
    kpiGraphSetting: {
      handler(t) {
        Object.keys(t).forEach((graphIndex) => {
          const historyTabOn = !!t[graphIndex];
          if (historyTabOn) {
            this.graphKpis[graphIndex].sensors.forEach((sensor) => {
              this.$store.dispatch('GET_SENSOR_MEASUREMENTS', {
                assetId: this.assetId,
                sensorId: sensor.id,
                last: this.kpiGraphSetting[graphIndex],
              });
            });
          }
        });
      },
      deep: true,
    },
  },
  beforeDestroy() {
    // if (this.checkVPNInterval) {
    //   clearInterval(this.checkVPNInterval);
    // }
  },
});
</script>

<style lang="scss">
.apexcharts-legend-marker {
  top: -3px;
}
</style>
